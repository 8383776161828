import { useState, useEffect } from "react";
import { Col, Container, Row, Table } from 'react-bootstrap';
import { Outlet, useNavigate, useParams, useLocation } from "react-router-dom";
import Header from "./Header";
import SearchBar from "./SearchBar";
import TableItems from "./TableItems";
import { useGetOrdersByAgentQuery, useGetSearchQuery } from "../store/apiSlice";
import Loading from "./Spinner";
import PaginationComponent from "./PaginationComponent";
import { useSelector } from "react-redux";

const OrdersDashBoard = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const navigate = useNavigate();
  const { agentName } = useParams();
  const location = useLocation();

  const [currentPage, setCurrentPage] = useState(0);
  const [dataPerPage] = useState(20);

  //const agent = useSelector(state => state.auth.user);

  const handleSearchChange = (value) => {
    setSearchQuery(value);
    setCurrentPage(0);
    navigate(`?search=${encodeURIComponent(value)}`);
  };

  const pageParamQuery = `?pageNo=${currentPage}&pageSize=${dataPerPage}`;
  const searchParamQuery = `?id=${agentName}&search=${searchQuery}&pageNo=${currentPage}&pageSize=${dataPerPage}`;

  //const { data, isLoading, isSuccess } = useGetOrdersByAgentQuery(`${agentName}${pageParamQuery}`);
  const { data: searchData, isLoading: searchLoading, isSuccess: searchSuccess } = useGetSearchQuery(`${searchParamQuery}`);

  useEffect(() => {
    // Parse current page number and search query from URL query params
    const params = new URLSearchParams(location.search);
    const pageNo = parseInt(params.get("pageNo"));
    const searchQueryParam = params.get("search");

    // Update current page
    setCurrentPage(pageNo || 0);

    // Update search query state if searchQueryParam exists
    if (searchQueryParam !== null) {
      setSearchQuery(searchQueryParam);
    }
  }, [location.search]);

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);

    // Build the new URL with the current search query
    const newUrl = `${location.pathname}?search=${encodeURIComponent(searchQuery)}&pageNo=${pageNumber}&pageSize=${dataPerPage}`;

    // Update the URL
    navigate(newUrl);
  };

  //const mergedData = searchSuccess ? searchData : data;
  // Filter data based on search query
  const filteredData = searchData?.content.filter((item) =>
    Object.values(item).some((value) =>
      typeof value === "string" && value.toLowerCase().includes(searchQuery.toLowerCase())
    )
  );

  return (
    <>
      <Header />
      <main>
        <Container fluid className="orders-wrapper">
          <Row>
            <Col className="list-wrapper">
              {searchLoading ? (
                <Loading className={"spinner"} />
              ) : (
                <>
                  <h2>список заказов</h2>
                  <SearchBar handleSearch={handleSearchChange} searchQuery={searchQuery} />
                  <Table bordered hover className="orders-table">
                    <thead className="sticky-top">
                    <tr>
                      <th>последнее изменение</th>
                      <th>перевозчик</th>
                      <th>авто</th>
                      <th>водитель</th>
                      <th>статус заказа</th>
                    </tr>
                    </thead>
                    <tbody>
                    {searchLoading ? <Loading /> : searchSuccess && <TableItems data={filteredData || []} />}
                    </tbody>
                  </Table>
                </>
              )}
            </Col>
          </Row>
          <Row>
            <Col>
              {searchData?.totalPages > 0 && (
                <PaginationComponent
                  data={searchData}
                  paginate={paginate}
                  currentPage={currentPage}
                  dataPerPage={dataPerPage}
                />
              )}
            </Col>
          </Row>
        </Container>
        <Outlet />
      </main>
    </>
  )
};

export default OrdersDashBoard;
